import React, {useEffect} from 'react'

export default function Homepage() {
  useEffect(() => {
    console.log('test')
  })
  return (
    <div>index</div>
  )
}
